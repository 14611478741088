<template>
    <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        课程分类
      </template>
      <template #input>
        <a-button class="all_boder_btn" @click="state=1;level=0;parentCourseCategoryId=null;visible=true">新建分类</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :defaultExpandAllRows="true"
      :pagination="false"
      :rowKey="item=>item.courseCategoryId"
      :loading="loading"
      childrenColumnName="childList"
    >

      <template slot="show" slot-scope="item,row">
        <template v-if="!(row.first==1&&row.last==1)">
          <a v-if="row.first!=1" @click="courseCategoryId=row.courseCategoryId;moveData(1)">前移</a>
          <span v-if="row.first==0 && row.last==0"> | </span>
          <a v-if="row.last!=1" @click="courseCategoryId=row.courseCategoryId;moveData(2)">后移</a>
        </template>
        <span v-else>-</span>
      </template>

      <!-- 手动输入排序 -->
      <template slot="sort" slot-scope="item,row">
        <a-input-number id="inputNumber" :disabled="loading" v-model="row.sort" :min="1" @change="onSortChange(row)" />
      </template>

      <template slot="catalogue" slot-scope="item,row">
        <a v-if="!!row.doEdit" @click="state=2;courseCategoryId=row.courseCategoryId;level=row.level;visible=true;name=row.name;levelName=getLevelName(row.parentCourseCategoryId)">编辑</a>
        <span v-if="!!row.doEdit"> | </span>
        <a v-if="!!row.doDelete " @click="removeData(row.courseCategoryId)">删除</a>
        <span v-if="!!row.doDelete && row.level==1"> | </span>
        <a v-if="!!row.doAddChild && row.level==1" @click="state=1;level=row.level;levelName=row.name;parentCourseCategoryId=row.courseCategoryId;visible=true">新建子分类</a>
      </template>
    </a-table>

    <!-- 新建/编辑 -->
    <a-modal v-model="visible" :title="(state==1?'新建':'编辑')+(level==0 || state==2&&level==1?'':'子')+'分类'" :confirm-loading="confirmLoading" @ok="state==1?addData():setData()" okText="确认">
      <!-- 根分类 -->
      <template v-if="level==0 || state==2&&level==1">
        <a-row class="rows">
          <a-col span="5" class="left">分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入分类名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>
      <!-- 子分类 -->
      <template v-else>
        <a-row class="rows">
          <a-col span="5" class="left">当前分类</a-col>
          <a-col span="19" class="right">
            <div style="line-height:32px;">{{levelName}}</div>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col span="5" class="left">子分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入子分类名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "分类名称",
    dataIndex: "name",
    key: "name",
  },
  // {
  //   title: "终端展示排序",
  //   align: "left",
  //   dataIndex: "show",
  //   key: "show",
  //   scopedSlots: { customRender: "show" },
  // },
  {
    title: "终端展示排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "目录操作",
    // align: "center",
    dataIndex: "catalogue",
    width: '200px',
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      level: 0,
      levelName: '',
      courseCategoryId: null,
      parentCourseCategoryId: null,
      state: 1, // 1新建，2编辑
      visible: false,
      data: [],
      columns,
      name: '',
      loading: false,
      // expandedRowKeys: [],
      confirmLoading: false
    };
  }, // 事件处理器
  methods: {
    expandIcon(props) {
      if(props.record.childList && props.record.childList.length > 0){
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      }else{
        return <span style={{marginRight:8 }}></span>
      }
    },

    // 查询列表数据
    getList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/course/category/'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.data = res.data
        }
        this.loading = false
        this.name = ''
        this.levelName = ''
      }).catch(err=>{
        this.loading = false
        this.name = ''
        this.levelName = ''
      })
    },

    // 新建分类
    addData(){
      if(this.name == ''){
        if(this.level==0){
          this.$message.error('请输入分类名称')
        }else{
          this.$message.error('请输入子分类名称')
        }
        return false
      }else if(this.name.length > 4 && this.level==0){
        this.$message.error('分类名称不能超过4个字')
        return false
      }else if(this.name.length > 8){
        this.$message.error('分类名称不能超过8个字')
        return false
      }
      this.confirmLoading = true;
      this.$ajax({
        url: '/hxclass-management/course/category/',
        method: 'post',
        params: {
          name: this.name,
          parentCourseCategoryId: this.parentCourseCategoryId
        }
      }).then(res=>{
        this.confirmLoading = false;
        if(res.code == 200 && res.success){
          this.visible = false
          this.getList()
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 修改分类
    setData(){
      if(this.name == ''){
        if(this.level==0){
          this.$message.error('请输入分类名称')
        }else{
          this.$message.error('请输入子分类名称')
        }
        return false
      }else if(this.name.length > 4 && this.level==0){
        this.$message.error('分类名称不能超过4个字')
        return false
      }else if(this.name.length > 8){
        this.$message.error('分类名称不能超过8个字')
        return false
      }
      this.$ajax({
        url: '/hxclass-management/course/category/',
        method: 'put',
        params: {
          courseCategoryId: this.courseCategoryId,
          name: this.name
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.visible = false
          this.getList()
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 删除分类
    removeData(e){
      this.$confirm({
        title: '确定删除该标签吗?',
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url: '/hxclass-management/course/category/'+e,
            method: 'delete'
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.getList()
            }else{
              this.$message.error(res.message)
            }
          })
        }
      });
    },

    // 修改位置
    onSortChange(item) {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      let params = {
        courseCategoryId: item.courseCategoryId,
        sort: item.sort
      }
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/course/category/",
            method: "PUT",
            params: params
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.getList();
              _this.$message.success("操作成功");
            }else{
              _this.$message.error(res.message);
            }
          });
      },600)
    },

    // 移动
    moveData(e){
      if(this.loading){
        return false
      }else{
        this.loading = true
      }
      this.$ajax({
        url: '/hxclass-management/course/category/changeSort',
        method: 'put',
        params: {
          courseCategoryId: this.courseCategoryId,
          direction: e
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.getList()
          setTimeout(()=>{
            this.loading = false
          },2000)
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 查询父级分类
    getLevelName(id){
      let name = ''
      function forId(arr){
        arr.forEach(res=>{
          if(res.courseCategoryId == id){
            name = res.name
          }else if(res.childList && res.childList.length > 0){
            forId(res.childList)
          }
        })
      }
      forId(this.data)
      return name
    }
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList()
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap{
  min-height: 100%;
  .btn{
    margin: 0 24px 14px 0;
  }
  a{
    color: @theme;
  }
}
.rows{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
</style>
